/* This example requires Tailwind CSS v2.0+ */
import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import Footer from "./footer";
import Navbar from "./navbar";

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const siteTitle = data.site.siteMetadata.title;

  return (
    <div>
      <Navbar location={location} />
      <main>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
