import React, { Fragment, useRef, useEffect, memo } from "react";

import { Disclosure, Menu, Transition, Popover } from "@headlessui/react";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  ViewGridIcon,
  NewspaperIcon,
  HandIcon,
  BookOpenIcon,
  CurrencyDollarIcon,
  HeartIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const navigation = [
  { title: "Home", url: "/" },
  // {
  //   title: "Buy Bitcoin on FTX",
  //   url: "https://ftx.com/#a=millions",
  // },
  // { title: "Buy me a coffee", url: "https://www.buymeacoffee.com/sexyir" },
  // {
  //   title: "Explicit Newsletter",
  //   url: "https://www.getrevue.co/profile/sexy/members",
  // },
  {
    title: "Facebook",
    url: "https://www.facebook.com/sexyirhq/",
  },
  {
    title: "Instagram",
    url: "https://www.instagram.com/sexyirhq/",
  },
];
const profile = [
  { title: "Buy me a coffee", url: "https://www.buymeacoffee.com/sexyir" },
];

const socialMedia = [
  {
    name: "Newsletter",
    description:
      "Subscribe to my free newsletter, get Sexy Chilis straight into your mailbox. Easy!",
    href: "https://digest.sexyir.com/",
    icon: NewspaperIcon,
  },
  {
    name: "Explicit Newsletter",
    description:
      "The special explicit chilis at the convenience of your mailbox, enjoy the contents in your free time, no rush. At least 3 newsletter per week.",
    href: "https://www.getrevue.co/profile/sexy/members",
    icon: HeartIcon,
  },
  {
    name: "Facebook",
    description: "Casual photos. Safer for work.",
    href: "https://www.facebook.com/sexyirhq/",
    icon: BookOpenIcon,
  },
  {
    name: "Instagram",
    description: "Instagram has sexier contents.",
    href: "https://www.instagram.com/sexyirhq/",
    icon: ViewGridIcon,
  },
  // {
  //   name: "Twitter",
  //   description: "This is NSFW area.",
  //   href: "https://www.twitter.com/sexyirhq",
  //   icon: HandIcon,
  // },
];
const callsToAction = [
  {
    name: "Buy me a coffee",
    href: "https://www.buymeacoffee.com/sexyir",
    icon: CurrencyDollarIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = ({ location }) => {
  const data = useStaticQuery(graphql`
    query NavbarQuery {
      site {
        siteMetadata {
          title
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  const InjectScript = memo(({ script }) => {
    const divRef = useRef(null);

    useEffect(() => {
      if (divRef.current === null) {
        return;
      }
      // create a contextual fragment that will execute the script
      // beware of security concerns!!
      const doc = document.createRange().createContextualFragment(script);

      // clear the div HTML, and append the doc fragment with the script
      divRef.current.innerHTML = "";
      divRef.current.appendChild(doc);
    });

    return <div ref={divRef} />;
  });

  const author = data.site.siteMetadata?.author;
  const social = data.site.siteMetadata?.social;
  const title = data.site.siteMetadata.title;

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link key="link-to-home" to="/">
                    <StaticImage
                      className="h-12 w-12"
                      formats={["AUTO", "WEBP", "AVIF"]}
                      src="../images/logo.png"
                      width={100}
                      height={100}
                      quality={90}
                      alt={title}
                    />
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map(item => (
                      <Link
                        key={item.title}
                        to={item.url}
                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      >
                        {item.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <a href="https://ko-fi.com/Z8Z182TTQ" target="_blank">
                    <img
                      height="36"
                      style={{ border: "0px", height: "36px" }}
                      src="https://cdn.ko-fi.com/cdn/kofi1.png?v=3"
                      border="0"
                      alt="Buy Me a Coffee at ko-fi.com"
                    />
                  </a>
                </div>
              </div>
              <div className="-mr-2 flex items-center md:hidden">
                {/* Always show ko-fi button on mobile view */}
                <a href="https://ko-fi.com/Z8Z182TTQ" target="_blank">
                  <img
                    height="36"
                    style={{ border: "0px", height: "36px" }}
                    src="https://cdn.ko-fi.com/cdn/kofi1.png?v=3"
                    border="0"
                    alt="Buy Me a Coffee at ko-fi.com"
                    className="mr-2"
                  />
                </a>
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map(item => (
                <Link
                  key={item.title}
                  to={item.url}
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  {item.title}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
