import React from "react";

const Footer = () => {
  return (
    <footer className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <div className="mb-4 flex flex-col justify-content-center">
        <p className="text-center text-lg font-bold mb-1">
          If you like the content, please support me ❤️
        </p>
        <iframe
          id="kofiframe"
          src="https://ko-fi.com/sexyir/?hidefeed=true&widget=true&embed=true&preview=true"
          style={{
            border: "none",
            width: "100%",
            padding: "4px",
            background: "#f9f9f9",
          }}
          height="712"
          title="sexyir"
        ></iframe>
      </div>
      © {new Date().getFullYear()}, Built with ❤️ Chilis Supplier
    </footer>
  );
};

export default Footer;
